
import { get, post } from '@/util/axios';
//用户详细信息
export const cuserInfo = (data) => {
  return post(
    `/api/blade-user/cuser/info`,
    data
  )
}

//用户详细信息
export const listThreeMinePlate = () => {
  return get(
    `/api/lecent-park/wechat/user-plate/listThreeMinePlate`,

  )
};
