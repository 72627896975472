import {get,post} from '@/util/axios';
//获取手机验证码
export const sendValidate = (phone) => {
  return get(
   //   `/api/blade-resource/sms/endpoint/send-validate?phone=${phone}`,
   `/api/blade-resource/sms/endpoint/trigger-sms-code?phone=${phone}`
  )
};
//登录接口
export const thirdToken = (data) => {
    return post(
       '/api/blade-auth/third/token',
       data
    )
  };