<template>
  <div id="loader-wrapper">
    <div id="loader"><img src="../../assets/index/logo.png">
    </div>
    <div style="font-size:3rem;color:#3d75ff;">蓝天停车</div>
  </div>
</template>
<script>
import { thirdToken } from '@/api/login/index'
import { clearAccessToken, setAccessToken } from "@/util/store"
export default {
  data() {
    return {
      form: {
        loginType: "mini_login",
        userType: "owner",
        platform: "bm",
        tenantId: process.env.VUE_APP_TENANT_ID,
        token: "",
      }
    }
  },
  async created() {
    let source = this.$route.query.source;
    let token = this.$route.query.token;
    if (source && source == "banma") {
      window.sessionStorage.setItem("source", source);
      window.sessionStorage.setItem("token", token);
      this.form.token = token;
      await this.banmaLogin();
    }
  },
  methods: {
    async banmaLogin() {
      await thirdToken(this.form).then(res => {
        console.log(res);
        if (res.code == 200) {
          setAccessToken(res.data.access_token)
          localStorage.setItem('firstLogin', res.data.first_login)
          localStorage.setItem('userPhone', res.data.phone)
          this.$router.replace({ path: '/home' });
        }
      })
    },
  },

}
</script>
<style scoped>
@-webkit-keyframes gradient {
  50% {
    background-position: 100% 0;
  }
}
@keyframes gradient {
  50% {
    background-position: 100% 0;
  }
}
@-webkit-keyframes img {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes img {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin-reverse {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
#loader-wrapper {
  background-size: 400%;
  background-position: 0% 100%;
  background-color: #ffffff;
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  overflow: hidden;
}
#loader {
  display: flex;
  position: relative;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3d75ff;
  animation: spin 1.7s linear infinite;
  z-index: 11;
}
#loader:before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3d75ff;
  animation: spin-reverse 0.6s linear infinite;
}

#loader:after {
  content: "";
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-top-color: #3d75ff;
  animation: spin 1s linear infinite;
}
#loader img {
  margin: auto;
  align-items: center;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  animation: img 1.7s linear infinite;
}
</style>