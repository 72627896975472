import { Dialog } from "vant";
import router from "@/router/index";
import { getAccessToken } from "./store";

export const serialize = (data) => {
  let list = [];
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
};

export const isAuth = (url) => {
  let accessToken = getAccessToken();
  if (!accessToken) {
    Dialog.confirm({
      title: "标题",
      message: "当前您未登录，是否前往登录！",
    })
      .then(() => {
        router.push("/login");
        // on confirm
      })
      .catch(() => {
        // on cancel
      });
  } else {
    router.push(url);
  }
};
