import axios from "axios";
import { Dialog, Toast } from "vant";
import { getAccessToken } from "@/util/store";


import { toolsGuid, toolsEncode } from "./tools.js"
import md5 from "js-md5"



// 创建axios请求实例
const request = axios.create({
  baseURL: process.env.VUE_APP_URL, // 设置跨域代理接口统一的前置地址
  timeout: 30000,
});
console.log(process.env.VUE_APP_SAAS_MODE);
const saasMode = JSON.parse(process.env.VUE_APP_SAAS_MODE);

// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    config.url = saasMode ? config.url.replace(/\/api/, "") : config.url;
    // 在发送请求之前做些什么
    const meta = config.meta || {};
    config.headers["Authorization"] = `Basic c2FiZXI6c2FiZXJfc2VjcmV0`;
    if (getAccessToken()) {
      config.headers["Blade-Auth"] = "bearer " + getAccessToken(); // 让每个请求携带token--['Authorization']为自定义key 请根据实际情况自行修改
    }
    if (config.url.search('open-api')) {
      let uuid = toolsGuid()
      console.log(config.method);
      let dataJs = undefined;
      if (config.method === 'post') {
        dataJs = JSON.stringify(config.data);
      } else if (config.method === 'get') {
        console.log(config.params);
        dataJs = JSON.stringify(config.params);
      } else {
        dataJs = ''
      }
      console.log(dataJs);
      let _data = dataJs + 'b1d95b5680854824a77537689665f78c' + uuid
      let paramMap = {
      };
      if (config.method === 'get') {
        paramMap = {
          encryptData: encodeURI(dataJs)
        };
      }


      // 加密
      _data = md5.base64(_data)
      console.log("md5", _data)
      config.headers['X-Request-Id'] = uuid
      config.headers['Sign'] = _data
      config.headers['App-Id'] = 'pk6lTomomiQojfgnmO3M'
      config.params = paramMap;
    } else {
      if (config.method === "post" && meta.isSerialize === true) {
        config.data = serialize(config.data);
      }
    }







    //headers中配置serialize为true开启序列化

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

export const serialize = (data) => {
  let list = [];
  Object.keys(data).forEach((ele) => {
    list.push(`${ele}=${data[ele]}`);
  });
  return list.join("&");
};

// 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    console.log("response");
    let responseCode = response.data.code || response.status;
    // 只返回数据

    if (responseCode === 200) {
      return response.data;
    } else {
      Dialog({ message: response.data.msg });
      return response.data;
    }
  },
  (error) => {
    console.log(error);
    let responseCode = error.response.status || error.response.data.code;
    // if (responseCode === 401) {
    //   //  return  router.replace('/login')
    //   // }
    if (responseCode >= 500) {
      if (error.response.data.msg) {
        Toast.fail(error.response.data.msg);
      } else {
        Toast.fail("程序出错，请联系管理员");
      }
    } else if (responseCode >= 400) {
      if (error.response.data.msg) {
        Dialog({ message: error.response.data.msg });
      } else {
        Toast.fail("网络连接失败，请联系管理员");
      }
    }
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

const get = (url, data) =>
  request.get(url, {
    params: data,
  });
const post = (url, data) => request.post(url, data);
const DELETE = (url, data) => request.delete(url, data);
// const head = (url, data) => request.head(url, data)
// const options = (url, data) => request.options(url, data)
// const put = (url, data) => request.put(url, data)
// const patch = (url, data) => request.patch(url, data)

export { get, post, DELETE };
