import { get, post } from '@/util/axios';

// 用户注册完成，获取注册赠送的优惠劵

export const apiRegisterCoupon = () => {
    return get(
        `/api/lecent-park/wechat/coupon/list-by-register`,
    )
};

//我的优惠劵详情
export const apiCouponDetail = (couponId) => {
    return get(
        `/api/lecent-park/wechat/coupon/detail/${couponId}`,
    )
};



// 用户登录完成，获取登录赠送的优惠劵 （七天未登录）
export const apiByLoginCoupon = () => {
    return get(
        `/api/lecent-park/wechat/coupon/list-by-login`,
    )
};



//我的待使用优惠券
export const apiUnUseCoupon = () => {
    return get(
        `/api/lecent-park/wechat/coupon/un-use-coupon`,
    )
};

//我的已使用优惠劵券
export const apiUsedCoupon = () => {
    return get(
        `/api/lecent-park/wechat/coupon/used-coupon`,
    )
};

//我的已过期优惠劵
export const apiOverdueCoupon = () => {
    return get(
        `/api/lecent-park/wechat/coupon/overdue-coupon`,
    )
};

//给好友赠送优惠劵
export const apiGiveOtherCoupon = (params) => {
    return post(
        `/api/lecent-park/wechat/coupon/give-other-coupon?phone=${params.phone}&userCouponId=${params.userCouponId}`,
    )
};

// 亲朋好友赠送记录

export const apiMyGiveOtherCoupon = (giveOtherUserId) => {
    return get(
        `/api/lecent-park/wechat/coupon/list/${giveOtherUserId}`,
    )
};

// 我的可赠送优惠劵列表


export const apiMyAvailableList = () => {
    return get(
        `/api/lecent-park/wechat/coupon/available-give-list`,
    )
};

//   首次绑定车辆后获得优惠券

export const apiBindCarCouponList = () => {
    return get(
        `/api/lecent-park/wechat/coupon/list-by-bind`,
    )
};


// 用户支付时，可用的优惠劵列表
export const apiPayAvailableCoupon = (data) => {
    return get(
        `/api/lecent-park/wechat/coupon/pay-available-coupon?parklotId=${data.parklotId}&totalAmount=${data.totalAmount}&plate=${data.plate}`,
    )
};
// 用户支付时，不可用的优惠劵列表
export const apiPayUnAvailableCoupon = (data) => {
    return get(
        `/api/lecent-park/wechat/coupon/pay-un-available-coupon?parklotId=${data.parklotId}&totalAmount=${data.totalAmount}&plate=${data.plate}`,
    )
};

// 用户在某个车场出场缴费时，最大的优惠金额
export const apiMaxDiscountAmount = (data) => {
    return get(
        `/api/lecent-park/wechat/coupon/max-discount-amount?parklotId=${data.parklotId}&totalAmount=${data.totalAmount}`,
    )
};


//进入代缴费页面自动获取用户的专属优惠劵
export const apiListByTempPay = (openid) => {
    let url = '/api/lecent-park/wechat/coupon/list-by-temp-pay';
    if (openid) {
        url = url.concat(`?openId=${openid}`)
    }
    return get(url);
}

export const apiListByTempPayNotLogin = () => {
    let url = '/api/lecent-park/wechat/coupon/not-received-coupon-by-wait-pay';
    return get(url);
}

//进入支付成功页面获取优惠劵接口
export const apiListBySuccessPay = (openid) => {
    let url = '/api/lecent-park/wechat/coupon/list-by-success-pay';
    if (openid) {
        url = url.concat(`?openid=${openid}`);
    }
    return get(url);
}

// 进入支付成功页面获取是否有待领取的优惠劵（页面是否弹框的标示）
export const apiCouponFlagBySuccessPay = (openid) => {
    let url = '/api/lecent-park/wechat/coupon/coupon-flag-by-success-pay';
    if (openid) {
        url = url.concat(`?openid=${openid}`);
    }
    return get(url);
}

// 支付成功获取待领取的优惠劵
export const apiNotReceivedCouponBySuccessPay = () => {
    let url = '/api/lecent-park/wechat/coupon/not-received-coupon-by-success-pay;';
    return get(url);
}