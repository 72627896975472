import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Vant from "vant";
import { Toast } from "vant";
import "vant/lib/index.css";
// 截流
import Debounce from "@/util/Debounce.js";
Vue.component("Debounce", Debounce);
Vue.config.productionTip = false;
Vue.use(Vant);
Vue.prototype.$msg = Toast;
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = "智慧停车";
  }
  // 需要监控是否关注公众号
  if (to.name == "jieshunScanPay") {
    authPhone(to, from, next);
  } else {
    next();
  }
  next();
});

function authPhone(to, from, next) {
  console.log(to);
  const toast = Toast.loading({
    message: "加载中...",
    duration: 0,
  });
  toast.clear();
  next();
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
