export const getUserInfo = () => {
    return localStorage.getItem('userInfo')
}
export const setUserInfo = (obj) => {
    return localStorage.setItem('userInfo',obj)
}

export const removeUserInfo = () => {
    return localStorage.removeItem('userInfo')
}
export const getAccessToken = () => {
    return localStorage.getItem('accessToken')
}

export const setAccessToken = (accessToken) => {
    return localStorage.setItem('accessToken', accessToken)
}

export const clearAccessToken = () => {
    return localStorage.removeItem('accessToken')
}

export const clearAllStorage = () => {
    return localStorage.clear()
}

