<template>
  <transition name="fade">
    <van-overlay :show="show" @click="close" :lock-scroll="false">
      <div class="wrapper_coupon" @click.stop>
        <img :src="src" alt="" class="coupon-img" />
        <div class="coupon-title" v-html="couponTitle" v-if="couponTitle"></div>
        <div class="coupon-container">
          <div class="coupon-list">
            <slot name="content"></slot>
          </div>
          <div class="i-know" @click="handleClick">{{ clickText }}</div>
          <div class="close" @click="close"></div>
        </div>
      </div>
    </van-overlay>
  </transition>
</template>
<script>
/**
 * couponTitle 正上方标题
 * clickText 正下方按钮标题
 * src 背景图片地址
 * show 是否展示
 */
export default {
  props: {
    couponTitle: String,
    clickText: String,
    src: String,
    show: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("update:show", false);
    },
    handleClick() {
      this.$emit("update:show", false);
      this.$emit("handleClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all ease 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>