<template>
  <div class="container">
    <div class="card-info">
      <div class="card-info-title">
        <div>
          <div class="my-car">我的车辆</div>
          <div><img style="width: 2.8rem;height: 1.4rem" alt="" src="../../assets/home/dim-icon.png"></div>
        </div>
        <div class="more" @click="toMore">更多</div>
      </div>

      <!-- 已登录状态 -->
      <van-swipe :autoplay="5000" indicator-color="white" vertical style="height: 28rem" v-if="loginStatus" :show-indicators="false">
        <template v-if="plateList.length>0">
          <van-swipe-item v-for="(item,i) in plateList" :key="i">
            <div class="card card-bg">
              <!-- 车辆在场 -->
              <template v-if="item.enterTime!==''">
                <div>
                  <div class="plate">
                    <div>{{ item.plate }}</div>
                    <div v-show="item.cardEndTime!==''">月卡</div>
                  </div>
                  <div>
                    <div class="park">
                      <div>车辆在场-{{item.parklotName}}</div>
                      <div class="flex-SB">
                        <div>入场时间</div>
                        <div>{{ item.enterTime }}</div>
                      </div>
                      <div class="flex-SB">
                        <div>已停</div>
                        <div>{{ item.parkTime }}</div>
                      </div>
                    </div>
                  </div>
                </div>

              </template>
              <!-- 车辆未在场 -->
              <template v-else>
                <div>
                  <div class="plate">
                    <div>{{ item.plate }}</div>
                    <div v-show="item.cardEndTime!==''">月卡</div>
                  </div>
                  <div class="login-desc">
                    <div>车辆未在场</div>
                    <div>智慧停车，尊享便捷车主服务</div>
                  </div>
                </div>
              </template>
              <div class="time" v-show="item.cardEndTime!==''" :style="item.needPay===1 ? {color:'#FF6969'} : ''">有效期至: {{ item.cardEndTime }}
              </div>
              <div class="to-cost" v-show="item.needPay===1" @click="goPay(item.plate)">去缴费</div>
            </div>
          </van-swipe-item>
        </template>
        <template v-else>
          <van-swipe-item>
            <div class="card card-bg">
              <template>
                <div>
                  <div class="login-desc">
                    <div>没有车辆</div>
                    <div>添加车辆享便捷智慧停车服务</div>
                  </div>
                  <div class="to-login" @click="$router.push('/bindCar')">添加爱车</div>
                </div>
              </template>
            </div>
          </van-swipe-item>
        </template>
      </van-swipe>
      <!-- 未登录显示状态 -->
      <van-swipe :autoplay="3000" indicator-color="white" v-else>
        <van-swipe-item>
          <div class="card card-bg1">
            <div>
              <div class="login-desc">
                <div>登录后</div>
                <div>添加车辆享便捷智慧停车服务</div>
              </div>
              <div class="to-login" @click="$router.push('/login')">去登录</div>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>

    <div class="func-container">
      <div class="func-list">
        <div @click="routerJump('/searchPlate?isInit=1','代缴费按钮',true)">
          <div><img alt="" src="../../assets/home/icon1.png" /></div>
          <div>代缴费</div>
        </div>
        <div @click="routerJump('/monthCard','月卡缴费按钮')">
          <div><img alt="" src="../../assets/home/icon2.png" /></div>
          <div>月卡缴费</div>
        </div>

      </div>
    </div>
    <!-- banner -->
    <div class="advertisement">
      <img alt="" :src="advertisementUrl" />
    </div>

    <ad clickText="我知道了" :show.sync="couponShow" :couponTitle="couponTitle" :src="adBg">
      <template v-slot:content>
        <van-row class="list-item" v-for="(item, j) in registerCouponList" :key="j">
          <van-col span="6">
            <div class="money" v-if="item.couponType == 1">
              {{ item.reduceAmount }}<span>元</span>
            </div>
            <div class="money" v-else-if="item.couponType == 2">
              {{ item.discountAmount }}<span>折</span>
            </div>
            <div class="money" v-else-if="item.couponType == 3">
              {{ item.reduceHour }}<span>小时</span>
            </div>
          </van-col>
          <van-col span="18">
            <div class="title">{{ item.couponName }}</div>
            <div class="descrip" v-if="item.couponType == 1">
              满{{ item.fullAmount }}元使用
            </div>
          </van-col>
        </van-row>
      </template>
    </ad>

  </div>
</template>

<script>
import ad from "@/components/ad/ad"
import { cuserInfo, listThreeMinePlate } from '@/api/home/home'
import { apiRegisterCoupon, apiByLoginCoupon } from '@/api/coupon'
import { isAuth } from "@/util/util";
import { getAccessToken } from "@/util/store";
export default {
  components: {
    ad
  },
  name: "Home",
  data() {
    return {
      couponTitle: "送您新用户专享券",
      advertisementUrl: require('../../assets/home/advertisement.png'), //广告专用
      adBg: require('../../assets/home/index_coupon.png'),//广告背景
      loginStatus: false, //登录状态false未登录，true已登录
      plateList: [],//我的车辆List
      couponShow: false,
      registerCouponList: []
    }
  },
  mounted() {
    this.isLogin();
  },
  created() {

  },
  methods: {
    toMore() {
      //未绑定车辆
      if (!this.loginStatus) {
        this.$router.push('login')

      } else {
        this.$router.push('/myCar')
      }
    },
    //判断当前是否登录
    isLogin() {
      this.loginStatus = !!getAccessToken();

      this.loginStatus ? this.initData() : '';
      this.couponShow = false
      // 注册 （登录后判断）
      if (this.loginStatus) {
        let firstLogin = localStorage.getItem("firstLogin")
        if (firstLogin == '') return
        firstLogin = firstLogin.toString()
        console.log(firstLogin, "firstLogin")
        if (firstLogin == "true") {
          // 注册 送优惠券
          let regitTimer = setTimeout(() => {
            apiRegisterCoupon().then((res) => {
              // console.log(res)
              this.couponTitle = "送您新用户专享券"
              let registerCoupon = res.data

              this.couponShow = registerCoupon.length === 0 ? false : true
              this.registerCouponList = res.data
              console.log(res)
              clearTimeout(regitTimer)
            })
          }, 0)

          localStorage.setItem('firstLogin', '')
        }
        if (firstLogin == "false") {
          // 七天未登录
          let regitTimer = setTimeout(() => {
            apiByLoginCoupon().then((res) => {
              // console.log(res)
              let registerCoupon = res.data
              this.couponTitle = `好久不见,欢迎回来送您<span style="color: #FF882F;">${registerCoupon.length}</span>张优惠券`

              this.couponShow = registerCoupon.length === 0 ? false : true
              this.registerCouponList = res.data
              clearTimeout(regitTimer)
            })
          }, 1000)
          localStorage.setItem('firstLogin', '')
        }

      }


    },
    //登录后执行数据化初始
    initData() {
      // this.getCuserInfo()
      this.getListThreeMinePlate()
    },
    toURL(url) {
      isAuth(url);
    },
    goPay(plate) {
      this.$router.push('/parkingRecord?plate=' + plate);
    },
    // getCuserInfo() {
    //     cuserInfo().then((res) => {
    //         console.log('首页', res)
    //     })
    // },
    //首页获取车牌
    getListThreeMinePlate() {
      listThreeMinePlate().then((res) => {
        this.plateList = res.data;
      })
    },
    // 跳转月卡缴费
    routerJump(src, noAuth) {
      // 未登录
      if (!noAuth && !this.loginStatus) {
        this.$router.push('login')
      } else {
        this.$router.push(src)
      }
    },
    onHandleJump(index, path, desc, noAuth) {
      const { aplus_queue } = window;
      aplus_queue.push({
        action: 'aplus.record',
        arguments: ['on_click_index_page_' + index, 'CLK', {
          from: '首页',
          to: path,
          describe: desc
        }]
      });
      this.routerJump(path, noAuth)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-bg {
  background-image: url("../../assets/home/card-bg.png");
}

.card-bg1 {
  background-image: url("../../assets/home/card-bg1.png");
}

.container {
  padding: 1.6rem 1.6rem 2rem;
  background-color: #f6f7fb;
  height: calc(100vh - 7.8rem);
  overflow-y: auto;

  .card-info {
    height: 34rem;

    &-title {
      display: flex;
      justify-content: space-between;

      .my-car {
        font-size: 1.6rem;
        font-weight: 500;
        color: #303030;
      }

      .more {
        font-size: 1.4rem;
        font-weight: 400;
        color: #666666;
      }
    }

    .card {
      height: 28rem;
      background-size: 100% 100%;
      position: relative;

      .plate {
        position: absolute;
        top: 3.5rem;
        left: 9.6rem;
        display: flex;
        justify-content: space-between;

        > div:first-child {
          font-size: 2rem;
          height: 2.7rem;
          font-weight: bold;
          color: #ffffff;
          text-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        }

        > div:last-child {
          width: 3.6rem;
          height: 1.7rem;
          margin: 0.5rem 1rem 0;
          background-color: #eab50c;
          color: #ffffff;
          font-size: 1.2rem;
          border-radius: 4px;
        }
      }

      .park {
        width: 28rem;
        position: absolute;
        top: 8rem;
        left: 2rem;

        > div:first-child {
          font-size: 1.4rem;
          font-weight: 500;
          color: #ffffff;
          text-align: left;
        }

        .flex-SB {
          display: flex;
          justify-content: space-between;
          margin-top: 1rem;
          font-size: 1.4rem;
          font-weight: 300;
          color: #ffffff;
        }
      }

      .time {
        font-size: 1.2rem;
        font-weight: 400;
        color: #ffffff;
        position: absolute;
        bottom: 6.4rem;
        left: 1.8rem;
      }

      .to-cost {
        width: 7.5rem;
        height: 2.8rem;
        line-height: 2.8rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: #ff7373;
        position: absolute;
        bottom: 5.8rem;
        right: 2rem;
        background: linear-gradient(
          180deg,
          rgba(235, 246, 255, 0.81) 0%,
          #fbfdff 100%
        );
        box-shadow: -3px 0px 4px 0px rgba(255, 255, 255, 0.39);
        border-radius: 1.2rem;
        border: 1px solid #ff2e2e;
      }

      .login-desc {
        width: 30rem;
        text-align: left;
        position: absolute;
        top: 9rem;
        left: 2rem;

        > div:first-child {
          font-size: 2rem;
          font-weight: 600;
          color: #ffffff;
        }

        > div:last-child {
          font-size: 1.2rem;
          font-weight: 300;
          color: #ffffff;
        }
      }

      .to-login {
        width: 7.2rem;
        height: 2.8rem;
        line-height: 2.8rem;
        font-size: 1.4rem;
        font-weight: 500;
        color: #ffffff;
        box-shadow: -2px 0px 4px 0px rgba(255, 255, 255, 0.5);
        border-radius: 1.2rem;
        position: absolute;
        right: 3.5rem;
        bottom: 15rem;
      }
    }
  }

  .func-container {
    border-radius: 1.2rem;
    overflow: hidden;
    margin-bottom: 1.6rem;

    .func-list {
      height: 12rem;
      padding: 0 4rem;
      display: flex;
      align-items: center;
      background: rgba(255, 255, 255, 0.49);
      border: 1px solid rgba(255, 255, 255, 0.49);
      border-image: linear-gradient(
          112deg,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 1)
        )
        1 1;

      > div {
        font-size: 1.4rem;
        font-weight: 400;
        color: #666666;
        line-height: 2rem;
        flex: 1;
        img {
          width: 4.8rem;
          height: 4.8rem;
        }
      }
    }
  }

  .advertisement {
    height: 12rem;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

// 优惠券相关
/deep/ .van-overlay {
  z-index: 12;
}
.wrapper_coupon {
  position: relative;
  width: 100vw;
  height: 100vh;
  background-image: url("../../assets/home/index_coupon.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 20%;
  .coupon-title {
    position: absolute;
    left: 28%;
    bottom: 88%;
    width: 49%;
    font-size: 1.7rem;
    font-weight: 600;
    color: #ffffff;
    text-align: center;
    span {
      color: #ff882f;
    }
  }
  .coupon-list {
    position: absolute;
    width: 28rem;
    // height: 23rem;
    height: 18rem;
    top: 28rem;
    left: 50%;
    transform: translateX(-50%);
    overflow-y: auto;
    .list-item {
      height: 6rem;
      margin-bottom: 1rem;
      background: #ffffff;
      border-radius: 0.67rem;
      display: flex;
      align-items: center;
      // /deep/ .van-col{
      //   position: relative;
      //   top:50%;
      //   transform: translateY(-50%);

      // }
      .money {
        font-size: 3.08rem;
        font-weight: bold;
        color: #ff2e2e;
        span {
          font-size: 1.2rem;
        }
      }
      .title {
        text-align: left;
        font-size: 1.4rem;
        font-weight: 600;
        color: #303030;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .descrip {
        text-align: left;
        font-size: 1.2rem;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .close {
    position: absolute;
    left: 50%;
    bottom: 5rem;
    transform: translateX(-50%);
    width: 3rem;
    height: 3rem;
    background-image: url("../../assets/home/close.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
  .i-know {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 12rem;
    height: 4rem;
    line-height: 4rem;
    text-align: center;
    background: #3d90ff;
    border-radius: 1.83rem;
    color: #fff;
    font-size: 1.4rem;
    bottom: 10rem;
  }
}
</style>
