import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/index.vue";
import Index from "../views/index/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/monthCard",
    name: "monthCard",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/monthCard/index"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },
  {
    path: "/monthPayResult",
    name: "monthPayResult",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/monthCard/monthPayResult"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },
  {
    path: "/monthCardList",
    name: "monthCardList",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/monthCard/monthCardList"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },
  {
    path: "/monthCardInfo",
    name: "monthCardInfo",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/monthCard/monthCardInfo"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },
  {
    path: "/renewRecord",
    name: "renewRecord",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/monthCard/renewRecord"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },

  {
    path: "/searchResult",
    name: "searchResult",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/monthCard/searchResult"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },
  {
    path: "/searchPlate",
    name: "searchPlate",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/searchPlate/index"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },
  {
    path: "/parkingRecord",
    name: "parkingRecord",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/searchPlate/parkingRecord"),
    meta: {
      title: "出场信息",
      keepAlive: false,
    },
  },
  {
    path: "/renewal",
    name: "renewal",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/searchPlate/payResult"),
    meta: {
      title: "订单信息",
      keepAlive: false,
    },
  },
  {
    path: "/myCar",
    name: "myCar",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/my/myCar/index"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },
  {
    path: "/carDetails",
    name: "carDetails",
    component: () =>
      import(
        /* webpackChunkName: "my" */ "../views/my/myCar/components/cardetails"
      ),
    meta: {
      title: "我的车辆",
      keepAlive: false,
    },
  },
  {
    path: "/bindCar",
    name: "bindCar",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/my/myCar/bindCar"),
    meta: {
      title: "智慧停车",
      keepAlive: false,
    },
  },

  {
    path: "/jieshun/scanPay",
    name: "jieshunScanPay",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/scanPay/index"),
    meta: {
      title: "扫码缴费",
      keepAlive: false,
    },
  },
  {
    path: "/jieshu/monthCard",
    name: "jieshu-monthCard",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/monthCard/index"),
    meta: {
      title: "车主服务",
      keepAlive: false,
    },
  },
  {
    path: "/jieshu/monthPayResult",
    name: "jieshu-monthPayResult",
    component: () =>
      import(
        /* webpackChunkName: "my" */ "../views/jieshun/monthCard/monthPayResult"
      ),
    meta: {
      title: "车主服务",
      keepAlive: false,
    },
  },
  {
    path: "/jieshu/monthCardList",
    name: "jieshu-monthCardList",
    component: () =>
      import(
        /* webpackChunkName: "my" */ "../views/jieshun/monthCard/monthCardList"
      ),
    meta: {
      title: "车主服务",
      keepAlive: false,
    },
  },
  {
    path: "/jieshu/monthCardInfo",
    name: "jieshu-monthCardInfo",
    component: () =>
      import(
        /* webpackChunkName: "my" */ "../views/jieshun/monthCard/monthCardInfo"
      ),
    meta: {
      title: "车主服务",
      keepAlive: false,
    },
  },
  {
    path: "/jieshu/renewRecord",
    name: "jieshu-renewRecord",
    component: () =>
      import(
        /* webpackChunkName: "my" */ "../views/jieshun/monthCard/renewRecord"
      ),
    meta: {
      title: "车主服务",
      keepAlive: false,
    },
  },
  {
    path: "/jieshu/searchResult",
    name: "jieshu-searchResult",
    component: () =>
      import(
        /* webpackChunkName: "my" */ "../views/jieshun/monthCard/searchResult"
      ),
    meta: {
      title: "车主服务",
      keepAlive: false,
    },
  },
  {
    path: "/ct/cardPayDetail",
    name: "cardPayDetail",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/monthCard/monthRenew"),
    meta: {
      title: "车主服务",
      keepAlive: false,
    },
  },
  {
    path: "/ct/tempPayDetail",
    name: "tempPayDetail",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/temporaryStop/index"),
    meta: {
      title: "车主服务",
      keepAlive: false,
    },
  }, {
    path: "/payRes",
    name: "payRes",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/payRes/index"),
    meta: {
      title: "支付结果",
      keepAlive: false,
    },
  },
  {
    path: "/ct/monthCardCreateOld",
    name: "monthCardCreateOld",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/monthCard/monthCardCreate"),
    meta: {
      title: "月卡开卡",
      keepAlive: false,
    },
  },
  {
    path: "/ct/card",
    name: "card",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/monthCard/monthCardQuery"),
    meta: {
      title: "月卡开卡",
      keepAlive: false,
    },
  },
  {
    path: "/ct/monthCardCreate",
    name: "monthCardCreate",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/monthCard/monthCardCreateSelectTime"),
    meta: {
      title: "月卡开卡",
      keepAlive: false,
    },
  },
  {
    path: "/ct/monthCardCreateDetail",
    name: "monthCardCreateDetail",
    component: () =>
      import(/* webpackChunkName: "my" */ "../views/jieshun/monthCard/monthCardCreateDetail"),
    meta: {
      title: "月卡开卡",
      keepAlive: false,
    },
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
